import { PAYMENTS_MAIN_DETAILS_KEYS, PAYMENTS_SUB_BILLS_DETAILS_KEYS } from "../enums";
import { PROVIDER_OPTIONS, STATUS_OPTIONS } from "../enums/payments";

export const PAYMENTS_HEADER_TITLES = {
    PAYMENT_ID: 'ID платіжки',
    BILL_ID: 'ID білу',
    SUM_TRANSACTION: 'Сума операції',
    FEE: 'Комісія',
    AMOUNT_DEBITED: 'Сума списання з картки',
    STATUS: 'Статус в плітіжці',
    PAN: 'PAN',
    PAYMENT_AT: 'Дата оплати',
    CREATED_AT: 'Створено',
};


export const PAYMENTS_STATUS_OPTIONS = [
    { value: STATUS_OPTIONS.NEW, title: 'NEW' },
    { value: STATUS_OPTIONS.CONFIRMED, title: 'CONFIRMED' },
    { value: STATUS_OPTIONS.READY, title: 'READY' },
    { value: STATUS_OPTIONS.PENDING, title: 'PENDING' },
    { value: STATUS_OPTIONS.PAID, title: 'PAID' },
    { value: STATUS_OPTIONS.PARTIALPAID, title: 'PARTIAL PAID' },
    { value: STATUS_OPTIONS.REJECTED, title: 'REJECTED' },
    { value: STATUS_OPTIONS.RETURNED, title: 'RETURNED' },
    { value: STATUS_OPTIONS.UNKNOWN, title: 'UNKNOWN' },
    { value: '', title: 'Показати всі статуси' },
]

export const PAYMENTS_PROVIDER_OPTIONS = [
    { value: PROVIDER_OPTIONS.PROCARD, title: 'PROCARD' },
    { value: PROVIDER_OPTIONS.UAPAY, title: 'UAPAY' },
    { value: PROVIDER_OPTIONS.FUIB, title: 'FUIB' },
    { value: PROVIDER_OPTIONS.STOP, title: 'STOP' },
    { value: PROVIDER_OPTIONS.TAS, title: 'TAS' },
    { value: PROVIDER_OPTIONS.UNEX, title: 'UNEX' },
    { value: PROVIDER_OPTIONS.RADABANK, title: 'RADABANK' },
    { value: '', title: 'Показати всі провайдери' },
]

export const MAX_CSV_FILE_SIZE = 5000

export const PAYMENTS_LOCKED_MAIN_FIELDS = [
    PAYMENTS_MAIN_DETAILS_KEYS.AMOUNT,
    PAYMENTS_MAIN_DETAILS_KEYS.FEE,
    PAYMENTS_MAIN_DETAILS_KEYS.ID,
    PAYMENTS_MAIN_DETAILS_KEYS.PROVIDER,
    PAYMENTS_MAIN_DETAILS_KEYS.STATUS,
    PAYMENTS_MAIN_DETAILS_KEYS.USER_ID,
    PAYMENTS_MAIN_DETAILS_KEYS.APARTMENT_ACCOUNT_ID,
    PAYMENTS_MAIN_DETAILS_KEYS.CLIENT_NAME,
    PAYMENTS_MAIN_DETAILS_KEYS.CLIENT_PHONE,
    PAYMENTS_MAIN_DETAILS_KEYS.MCC,
    PAYMENTS_MAIN_DETAILS_KEYS.PAY_TYPE,
    PAYMENTS_MAIN_DETAILS_KEYS.PAYMENT_ID,
    PAYMENTS_MAIN_DETAILS_KEYS.PAYMENT_TYPE,
    PAYMENTS_MAIN_DETAILS_KEYS.SINGLE_RECEIPT_ID,
];

export const PAYMENTS_LOCKED_SUB_FIELDS = [
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.AMOUNT,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.FEE,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.PROVIDER,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.PROVIDER_TITLE,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_ID,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_PROVIDER_ID,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.STATUS,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.SERVICE_NAME,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.ACCOUNT,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.BANK_MFO,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.BANK_NAME,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.DESCRIPTION,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.NAME,
    PAYMENTS_SUB_BILLS_DETAILS_KEYS.ZKPO,
];
